import {LOGOUT, SEND_CODE, VERIFY_CODE} from '../actions/auth.actions';
import {isEmpty, getCode, getToken} from "../utils/helpers";

let initialState = {
    isLoggedIn: !isEmpty(getCode()) && !isEmpty(getToken()),
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGOUT.SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case SEND_CODE.SUCCESS:
            return {
                ...state,
            };
        case VERIFY_CODE.SUCCESS:
            return {
                ...state,
                isLoggedIn: action.payload.success,
            };
        case VERIFY_CODE.FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
};
