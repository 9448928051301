export const action = (type, payload = {}) => {
    return {type, ...payload};
};

export const verboseAction = (type, payload = {}) => {
    return {
        type: type,
        payload: {...payload}
    };
};

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const EMPTY = 'EMPTY';
const CLEAR = 'CLEAR';
const MESSAGE = 'MESSAGE';

export const createRequestTypes = (base) => {
    return [REQUEST, SUCCESS, FAILURE, CLEAR, EMPTY, MESSAGE].reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
};

export const actionCreator = {
    request: (type) => action(type.REQUEST),
    success: (type, payload) => action(type.SUCCESS, {payload}),
    failure: (type, error) => verboseAction(type.FAILURE, error),
    empty: (type) => action(type.EMPTY),
    clear: (type) => action(type.CLEAR),
    message: (type, payload) => action(type.MESSAGE, {payload}),
};


export const generateId = () => {
    return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()
};

export const getErrorMessage = (error) => {
    let mockErrorMessage = {};
    if (error && error.data) {
        mockErrorMessage.message = 'Unknown error please try again later';
    } else {
		mockErrorMessage.message = 'Unknown error please try again later';
    }

    return mockErrorMessage;
};

export const generateLocalStorageItem = (token, userId, verificationCode) => {
    if (token)
        localStorage.setItem('X-Auth-Token', JSON.stringify(token));
    if (userId)
        localStorage.setItem('userId', userId);
    if (verificationCode)
        localStorage.setItem('verificationCode', verificationCode);
};

export const getToken = () => {
    return localStorage.getItem('X-Auth-Token') ? JSON.parse(localStorage.getItem('X-Auth-Token')) : '';
};

export const getFileToken = () => {
    return localStorage.getItem('X-API-FILE-TOKEN') ? JSON.parse(localStorage.getItem('X-API-FILE-TOKEN')) : '';
};

export const setFileToken = (fileToken) => {
    localStorage.setItem('X-API-FILE-TOKEN', JSON.stringify(fileToken));
}

export const getCode = () => {
    return localStorage.getItem('verificationCode') || '';
};

export const getUserId = () => {
    return localStorage.getItem('userId') || '';
};

export const cleanLocalStorage = () => {
    localStorage.removeItem('X-Auth-Token')
    localStorage.removeItem('verificationCode')
    localStorage.removeItem('userId')
    localStorage.removeItem('X-API-FILE-TOKEN')
}

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0);
