import {useCallback, useMemo, useReducer} from "react";

const ACTIONS = {
    SET_STEP: "SET_STEP",
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_STEP:
            const { step, state: stepState} = action.payload;

            return {
                ...state,
                currentStep: step,
                prevStep: state.currentStep,
                steps: {
                    ...state.steps,
                    [step]: {...state.steps[step], ...stepState},
                },
            };
        default:
            throw new Error();
    }
}

const useSteps = (currentStep, stepsState) => {
    const [state, dispatch] = useReducer(reducer, {
        currentStep: currentStep,
        steps: {...stepsState}
    });
    
    const step = useMemo(() => {
        return state && state.currentStep || {}
    }, [state]);

    const prevStep = useMemo(() => {
        return state && state.prevStep;
    }, [state]);

    const steps = useMemo(() => {
        return state && state.steps;
    }, [state]);

    const stepState = useMemo(() => {
        return step && steps && steps[step]
    }, [step, steps]);

    const setStep = useCallback((step, state) => {
        if (typeof state === "function") {
            state = state({
                steps
            });
        }

        dispatch({ type: ACTIONS.SET_STEP, payload: {step: step, state: state}});
    }, [steps]);

    return [step, stepState, prevStep, setStep, steps];
}

export default useSteps;