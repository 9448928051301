import axios from 'axios';
import {API_ROOT} from './api-config';
import store from "../store";
import {logout} from "../actions/auth.actions";
import {getFileToken} from "./helpers";

const axiosLinkInstance = axios.create({
	paramsSerializer(params) {
		const searchParams = new URLSearchParams();
		for (const key of Object.keys(params)) {
			const param = params[key];
			if (Array.isArray(param)) {
				for (const p of param) {
					searchParams.append(key, p);
				}
			} else {
				searchParams.append(key, param);
			}
		}
		return searchParams.toString();
	},
	baseURL: `${API_ROOT}api/link-file-upload/`,
	timeout: 5 * 60 * 1000,
	headers: {
		'Content-Type': 'application/json'
	}
});

axiosLinkInstance.interceptors.request.use((config) => {
	config.headers['X-API-FILE-TOKEN'] = getFileToken();
	return config;
});

axiosLinkInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		const originalRequest = error.config;

		if (error.response && (error.response.status === 401 || error.response.status === 403)) {
			store.dispatch(logout());
		}

		return Promise.reject(error);
	}
);

export default axiosLinkInstance;
