import {GET_PATIENT_DETAILS} from "../actions/patient.actions";

let initialState = {
    patientDetails: {},
};

export const patientReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PATIENT_DETAILS.SUCCESS:
            return {
                ...state,
                patientDetails: action.payload.details,
            };
        default:
            return state;
    }
};
