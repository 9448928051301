import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {getPatientDetails} from '../../actions/patient.actions';
import {sendCode, verifyCode} from '../../actions/auth.actions';
import {getAuthData, getPatientData} from "../../utils/selectors";
import Header from "../Layout/Header";
import Loader from "react-spinners/BeatLoader";
import InvalidToken from "./LoginSteps/InvalidToken";
import SendCode from "./LoginSteps/SendCode";
import EnterCode from "./LoginSteps/EnterCode";
import {getToken} from "../../utils/helpers";
import {useLocation, useNavigate} from "react-router-dom";

const Login = ({ isLoggedIn, getPatientDetails, sendCode, verifyCode }) => {

    const [step, setStep] = useState("verifyToken");

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isLoggedIn !== true) {
            // Get token from URL
            let token = new URLSearchParams(location.search).get('token');

            if (token || getToken()) {
                // Get patient info and verify token simultaneously
                getPatientDetails(token).then(response => {

                    // If token is valid and call is successful
                    if (response && response.success) {

                        // Remove token from URL
                        navigate(location.pathname, {replace: true});

                        // Go to send code step
                        setStep("sendCode");
                    } else {
                        setStep("invalidToken");
                    }
                });
            }
            else {
                setStep("invalidToken");
            }
        }
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            if (location.state && location.state.from) {
                navigate(location.state.from);
            } else {
                navigate('/');
            }
        }
    }, [isLoggedIn]);

    return (
        <>
            <Header />
            <div className="login">
                <div className="login-box">
                    <h1 className="login-title">N1X10 Multiple File Upload</h1>
                    <div className="login-step">
                        {step === "verifyToken" &&
                            <Loader size={5} color={"#aaa"}/>
                        }

                        {step === "invalidToken" &&
                            <InvalidToken />
                        }

                        {step === "sendCode" &&
                            <SendCode
                                setActiveStep={setStep}
                                sendCode={sendCode}
                            />
                        }

                        {step === "enterCode" &&
                            <EnterCode
                                setActiveStep={setStep}
                                verifyCode={verifyCode}
                            />
                        }
                    </div>
                    <p className="login-note">
                        Trouble logging in?
                        <br/>
                        Please contact <a href="mailto:office@n1x10.com">office@n1x10.com</a>
                    </p>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => {
    const isLoggedIn = getAuthData(state).isLoggedIn;
    const patientDetails = getPatientData(state).patientDetails;

    return {
        isLoggedIn,
        patientDetails,
    };
};

const mapDispatchToProps = {
    getPatientDetails,
    sendCode,
    verifyCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
