import React, {useEffect, useMemo, useState} from "react";
import {getPasswordStrength} from "../utils/security";
import classNames from "classnames";
import PropTypes from "prop-types";

const getPasswordScoreLabel = (score) => {
    switch (score) {
        case 1:
            return "Weak";
        case 2:
            return "Almost";
        case 3:
            return "Strong";
        default:
            return "Very Weak";
    }
}

const getPasswordScoreClass = (score) => {
    switch (score) {
        case 1:
            return "weak";
        case 2:
            return "medium";
        case 3:
            return "strong";
        default:
            return "";
    }
}

const PasswordInput = (
    {
        value,
        onBlur,
        onChange,
        showPasswordStrength,
        onPasswordStrengthChange,
        placeholder = "Password*",
        name = "password"
    }) => {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const passwordScore = useMemo(() => {
        if (!showPasswordStrength) {
            return null;
        }

        return getPasswordStrength(value);
    }, [value, showPasswordStrength]);

    useEffect(() => {
        if (onPasswordStrengthChange) {
            onPasswordStrengthChange(passwordScore);
        }
    }, [passwordScore]);

    return (
        <div className="password-input">
            <div className="password-input__group">
                <input className="form-control form-control-rounded"
                       type={showPassword ? "text" : "password"}
                       placeholder={placeholder}
                       name={name}
                       value={value}
                       onChange={onChange}
                       onBlur={onBlur}
                />
                <span className="password-input__icon" onClick={togglePasswordVisibility}>
                    <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}/>
                </span>
            </div>
            {showPasswordStrength && (
                <div className="password-input__strength">
                    <div className="password-input__strength-indicator">
                        {Array(3).fill().map((_, index) => (
                            <div
                                className={classNames("password-input__strength-indicator-bar", {
                                    [`password-input__strength-indicator-bar--${getPasswordScoreClass(passwordScore)}`]: passwordScore > index
                                })}
                            />
                        ))}
                    </div>
                    <div className="password-input__strength-label">
                        {getPasswordScoreLabel(passwordScore)}
                    </div>
                </div>
            )}
        </div>
    )
}

PasswordInput.propTypes = {
    value: PropTypes.string,
    onBlur: PropTypes.func,
    showPasswordStrength: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string
}

export default PasswordInput;