import FileUploadLayout from "./FileUploadLayout";
import FileUpload from "../FileUpload/FileUpload";
import ResetPassword from "../Authentication/ResetPassword";
import AuthBaseLayout from "../Authentication/Common/AuthBaseLayout";
import LinkUploadFiles from "../FileUpload/LinkUploadFiles";
import LinkUploadFilesLayout from "./LinkUploadFilesLayout";

const components = {
    fileUpload: {
        url: '/file-upload',
        component: FileUpload,
        layout: FileUploadLayout
    },
    linkUploadFiles: {
        url: '/link-upload-files',
        component: LinkUploadFiles,
        layout: LinkUploadFilesLayout,
        public: true,
    },
    resetPassword: {
        url: '/reset-password',
        component: ResetPassword,
        layout: AuthBaseLayout,
        public: true,
    },
};

export const routes = [...Object.values(components)];