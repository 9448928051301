import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import axiosPublicInstance from "../../../utils/axios-public-instance";
import {toast} from "react-toastify";
import PropTypes from "prop-types";
import PasswordField from "../../../components/PasswordField";
import * as Yup from "yup";
import {useFormik} from "formik";

const NewPasswordStep = ({passwordToken, code, onAuthTokenReceived}) => {

    const [authToken, setAuthToken] = useState("");

    const resetPassword = useCallback(async (password) => {
        try {
            const resp = await axiosPublicInstance.post("/api/reset-password",
                {
                    token: passwordToken,
                    code: code,
                    password: password,
                    app: 'PATIENT_APP'
                });

            const { token } = resp.data;

            setAuthToken(token);
            if (onAuthTokenReceived) {
                onAuthTokenReceived(token);
            }
        } catch (error) {
            console.error(error);
            toast.error(error.message);
        }
    }, [passwordToken, code]);

    const handleOnSubmit = useCallback(async ({ password }, { setSubmitting }) => {
        try {
            await resetPassword(password)
        } finally {
            setSubmitting(false);
        }
    }, [resetPassword]);

    const validationSchema = useMemo(() => {
        return Yup.object().shape({
            password: Yup.string().required('Required'),
            passwordConfirm: Yup.string()
                .required('Required')
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: ''
        },
        validationSchema: validationSchema,
        onSubmit: handleOnSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit} className="new-password__form">
        <div className="auth-new-password">
                <div className="auth-new-password__title">Reset Password</div>
                <div className="auth-new-password__description">
                    Create new password
                </div>
                <div className="auth-new-password__password">
                    <PasswordField
                        value={formik.values.password}
                        placeholder="New password"
                        name="password"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        showPasswordStrength={true}
                    />
                    {formik.errors.password && formik.touched.password && (
                        <div className="invalid-feedback">{formik.errors.password}</div>
                    )}
                </div>
                <div className="auth-new-password__password-confirm">
                    <PasswordField
                        value={formik.values.passwordConfirm}
                        placeholder="Confirm new password"
                        name="passwordConfirm"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.errors.passwordConfirm && formik.touched.passwordConfirm && (
                        <div className="invalid-feedback">{formik.errors.passwordConfirm}</div>
                    )}
                </div>
                <div className="auth-new-password__guideline">
                    <div className="auth-new-password__guideline-title">
                        Strong password guidelines
                    </div>
                    <div className="auth-new-password__guideline-description">
                        <ul>
                            <li>Use at least 8 characters</li>
                            <li>Use a mixture of both uppercase and lowercase letters</li>
                            <li>include of at least one special character, e.g., ! @ # ? ]</li>
                        </ul>
                    </div>
                </div>
                <div className="auth-new-password__submit">
                    <button
                        className="btn btn-lg btn-rnd btn-primary
                        auth-new-password__submit-btn"
                        type="submit"
                        disabled={formik.isSubmitting || !formik.isValid || !formik.values.password}
                    >
                        Done
                    </button>
                </div>
        </div>
        </form>
    );
}

NewPasswordStep.propTypes = {
    passwordToken: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    onAuthTokenReceived: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordStep);