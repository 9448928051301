import React, {useCallback, useEffect, useState, useRef, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import {isEmpty} from "../../utils/helpers";
import axiosInstance from "../../utils/axios-instance";
import axios from "axios";
import Select from "react-select";
import _ from "lodash";
import {toast} from "react-toastify";
import moment from "moment";

const CustomSelect = ({ value, options, onChange, disabled }) => {
    const handleOnChange = useCallback((option) => {
        onChange(option.value);
    }, []);

    const selectedValue = useMemo(() => {
        return options.find(opt => opt.value === value);
    }, [value, options]);

    return (
        <Select
            options={options || []}
            isLoading={!options}
            closeMenuOnSelect={true}
            onChange={handleOnChange}
            value={selectedValue}
            isDisabled={disabled || _.isEmpty(options)}
        />
    );
}

const FileUpload = () => {

    const [files, setFiles] = useState({});
    const [fileDefinitions, setFileDefinitions] = useState({});
    const [folders, setFolders] = useState(null);
    const [defaultFolder, setDefaultFolder] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [progress, setProgress] = useState({});
    const progressRef = useRef(progress);
    const [stoppers, setStoppers] = useState({});
    const stoppersRef = useRef(stoppers);

    const fileTypeMatchers = useMemo(() => {
        return [
            {
                type: 'ER visits',
                patterns: ['^(?:.*#){2}(ER|er_notes|er_note)(?:\\.|$)'],
            }, {
                type: 'Doctor visits',
                patterns: ['^.*#(MD NOTE|MD NOTES)#'],
            }, {
                type: 'Imaging',
                patterns: ['^.*#(mri|ct|us|xr|pet_ct)#'],
            }, {
                type: 'Lab tests',
                patterns: ['^.*#(labs)#'],
            }, {
                type: 'Molecular/Genomic',
                patterns: ['^.*#(Genetics)#'],
            }, {
                type: 'Pathology',
                patterns: ['^.*#(pathology)#'],
            }, {
                type: 'Hospital reports',
                patterns: ['^.*#(Discharge Summaries)#'],
            }, {
                type: 'Other',
                patterns: ['^.*#(operative report|ChemoRadiation Treatment Reports|Radiology Other)#'],
            }
        ]
    }, []);

    const findFolderNameInFileName = useCallback((fileName) => {
        if (_.isEmpty(fileName)) {
            return null;
        }

        for (const ftm of fileTypeMatchers)  {
            for (const pattern of ftm.patterns) {
                if (fileName.match(new RegExp(pattern, 'gmi'))) {
                    return ftm.type;
                }
            }
        }

        return null;
    }, [fileTypeMatchers]);

    const onDrop = useCallback(acceptedFiles => {
    }, []);

    const {
        rootRef, inputRef,
        getRootProps, getInputProps,
        isDragAccept, isDragActive, isDragReject, isFileDialogActive, isFocused,
        acceptedFiles, draggedFiles, fileRejections,
        open,
    } = useDropzone({onDrop});

    const removeFile = (name) => {
        const updatedFiles = {...files};
        delete updatedFiles[name];
        setFiles(updatedFiles);

        const updatedFileDefinitions = {...fileDefinitions};
        delete updatedFileDefinitions[name];
        setFileDefinitions(updatedFileDefinitions);

        const updatedProgress = {...progress};
        delete updatedProgress[name];
        setProgress(updatedProgress);

        const updatedStoppers = {...stoppers};
        delete updatedStoppers[name];
        setStoppers(updatedStoppers);
    }

    const removeAll = () => {
        setFiles({});
        setFileDefinitions({});
        setProgress({});
        setStoppers({});
    }

    const startUpload = () => {

        // Generate progress starting points
        let updatedProgress = {...progress};
        Object.keys(updatedProgress).map((name) => {
            if (updatedProgress[name].percentage === 0) updatedProgress[name].percentage = 1;
        });
        setProgress(updatedProgress);

        // Generate stop tokens
        let updatedStoppers = {};
        Object.keys(files).forEach((name, index) => {

            // Only for still not uploaded files
            if (progress[name].percentage !== 100) {
                updatedStoppers[name] = axios.CancelToken.source();
            }
        });
        setStoppers(updatedStoppers);

        Object.keys(files).forEach((name, index) => {

            // Don't upload files a second time
            if (progress[name].percentage !== 100) {

                const fileDefinition = fileDefinitions[name];

                // Get the file to upload
                let formData = new FormData();
                formData.append("file", files[name]);
                for (const prop in fileDefinition) {
                    const propVal = fileDefinition[prop];
                    if (propVal !== undefined) {
                        formData.append(prop, fileDefinition[prop]);
                    }
                }

                if (fileDefinition.extractor) {
                    formData.set("extractorId", fileDefinition.extractor.id);
                }

                // Fire the API call
                axiosInstance.post(`api/file/upload/${fileDefinition.folderId}`, formData, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    cancelToken: updatedStoppers[name].token,
                    onUploadProgress: (progressEvent) => {

                        // Calculate progress percentage
                        let progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                        // Work around very small files going from 0 straight to 100
                        if (progressPercentage === 0) {progressPercentage = 1;}
                        if (progressPercentage === 100) {progressPercentage = 99;}

                        // Store progress
                        let updatedProgress = {...progressRef.current}
                        updatedProgress[name] = {percentage: progressPercentage};
                        setProgress(updatedProgress);
                    }
                })
                .then((response) => {
                    const updatedProgress = {...progressRef.current}
                    const fileProgress = {...updatedProgress[name]};
                    updatedProgress[name] = fileProgress;

                    if (response && response.data && response.data.success) {
                        fileProgress.percentage = 100;
                        fileProgress.success = true;

                        if (response.data.message) {
                            fileProgress.msg =  response.data.message;
                        }

                        const fileId = _.get(response.data, 'actionResults[0].file.id');
                        if (fileId) {
                            const updatedFileDefinition = {...fileDefinitions[name], id: fileId};
                            setFileDefinitions({...fileDefinitions, [name]: updatedFileDefinition});
                        }
                    } else {
                        fileProgress.percentage = 0;
                        fileProgress.success = false;

                        // Log error
                        console.log(response && response.data && response.data.message)
                    }

                    setProgress(updatedProgress);
                })
                .catch((error) => {
                    const updatedProgress = {...progressRef.current}
                    const fileProgress = {...updatedProgress[name]};
                    updatedProgress[name] = fileProgress;

                    const responseData = (error && error.response && error.response.data) || null;

                    fileProgress.percentage = 0;
                    fileProgress.success = false;

                    if (responseData && responseData.success === false) {
                        if (responseData.errorMessage) {
                            fileProgress.msg = responseData.errorMessage;
                        }
                    }

                    setProgress(updatedProgress);

                    // Log error
                    console.log(error)
                })
                .finally(() => {

                    let updatedStoppers = {...stoppersRef.current};
                    delete updatedStoppers[name];
                    setStoppers(updatedStoppers);
                });
            }
        });
    }

    const cancelUpload = () => {

        // Get current progress
        let updatedProgress = {...progress};

        Object.keys(files).map(name => {

            // For files that are still uploading
            if (progress[name].percentage < 100) {

                // Stop API Call
                stoppers[name].cancel();

                // Reset Progress
                updatedProgress[name].percentage = 0;
            }
        });

        // Update progress
        setProgress(updatedProgress);
    }

    const getFileSizeText = (bytes) => {
        if (bytes == null) {
            bytes = 0;
        }

        if (bytes < 1_000) {
            return bytes + " bytes"
        } else if (bytes < 1_000_000) {
            return (bytes / 1_000) + " KB";
        } else if (1_000_000_000) {
            return (bytes / 1_000_000) + " MB";
        } else {
            return (bytes / 1_000_000_000) + " GB";
        }
    }

    const updateFileDefinition = useCallback((fileName, fileDefinition) => {
        const updatedDefinitions = {...fileDefinitions};

        updatedDefinitions[fileName] = {...updatedDefinitions[fileName], ...fileDefinition};

        setFileDefinitions(prevState => {
            const updatedDefinitions = {...prevState};

            updatedDefinitions[fileName] = {...updatedDefinitions[fileName], ...fileDefinition};

            return updatedDefinitions;
        });
    }, []);

    useEffect(() => {
        // load folders
        axiosInstance.get('/api/file/folders').then((resp) => {
            if (resp.data.success) {
                const folders = resp.data.folders;
                setFolders(folders);
                setDefaultFolder(folders.find(f => f.folderName === "Other"));
            }
        });
    }, []);

    // On files added
    useEffect((prevProps) => {

        let updatedFiles = {...files};
        let updatedFileDefinitions = {...fileDefinitions};

        // Update accepted files
        acceptedFiles.forEach(file => {

            // If file is already selected - alert user
            if (updatedFiles && updatedFiles.hasOwnProperty(file.name) && updatedFiles[file.name].size === file.size) {
                // alert(file.name + ' already selected')
                toast.error(file.name + ' already selected');
            }
            // If file is new - add
            else {
                let folderId = defaultFolder.id;
                const folderName = findFolderNameInFileName(file.name);

                if (folderName) {
                    const folder = folders.find(folder => folder.folderName.toLowerCase() === folderName.toLowerCase());
                    if (folder) {
                        folderId = folder.id;
                    }
                }

                let effectiveDate = null;
                const dateMatch = file.name && file.name.match(/^(\d{2}-\d{2}-\d{4})#.*#/i);
                if (dateMatch && dateMatch.length > 1) {
                    effectiveDate = moment(dateMatch[1], 'MM-DD-YYYY').format('yyyy-MM-DD');
                } else {
                    effectiveDate = moment().format('yyyy-MM-DD');
                }

                updatedFiles[file.name] = file;
                updatedFileDefinitions[file.name] = {
                    fileName: file.name,
                    effectiveDate: effectiveDate,
                    contentType: file.type,
                    folderId: folderId,
                };
            }
        });
        setFiles(updatedFiles);
        setFileDefinitions(updatedFileDefinitions);

        // Generate upload progress entry
        let updatedProgress = {...progress};
        Object.keys(updatedFiles).forEach((name) => {
            if (!progress.hasOwnProperty(name)) updatedProgress[name] = { percentage: 0 };
        });
        setProgress(updatedProgress);

    }, [acceptedFiles]);

    // On progress update
    useEffect(() => {

        // Update uploading status
        setUploading(Object.keys(progress).filter(file => progress[file].percentage > 0 && progress[file].percentage < 100).length);

        // Update uploaded status
        setUploaded(Object.keys(progress).length > 0 && Object.keys(progress).length === Object.keys(progress).filter(name => progress[name].percentage === 100).length);

        // Update progress ref for use in async calls
        progressRef.current = progress;

    }, [progress]);

    // On stoppers update
    useEffect(() => {

        // Update stoppers ref for use in async calls
        stoppersRef.current = stoppers;

    }, [stoppers]);

    return (
        <div className="uploader">
            <div className="row">
                <div className="col col-lg-12">

                    <h2>N1X10 Multiple file upload</h2>

                    <p className="uploader-input">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={open}
                            disabled={uploading}
                        >
                            <span>+</span>
                        </button>
                        Drag and drop files onto this window to upload or browse your computer to select a file
                    </p>

                </div>
            </div>
            <div className="row">
                <div className="col col-lg-12">
                    <div
                        className={"uploader-box" + (isDragActive ? " uploader-box-dragging" : "")}
                         {...getRootProps()}
                    >

                        <div
                            className="uploader-box-header"
                            onClick={(e) => {e.stopPropagation()}}
                        >
                            <h4 className="uploader-box-title">Files to upload</h4>
                            {!isEmpty(files) &&
                                <button
                                    className="btn btn-link"
                                    onClick={removeAll}
                                    disabled={uploading}
                                >
                                    Remove all
                                </button>
                            }
                        </div>

                        <div
                            className="uploader-box-files"
                            onClick={(e) => {!isEmpty(files) && e.stopPropagation()}}
                        >

                            <input {...getInputProps()} />

                            {isEmpty(files) && (isDragActive ?
                                <p>Drop the files here&hellip;</p> :
                                <p>Drag 'n' drop some files here, or click to select files.</p>
                            )}

                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Effective Date</th>
                                        <th>Type</th>
                                        <th>Progress</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                {files && Object.keys(files).map((name, index) => {
                                    const file = files[name];
                                    const fileDef = fileDefinitions[name];
                                    const canEdit = !progress[name].percentage;

                                    return (
                                        <tr key={file.name}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <h6 className={progress[name].success === false ? 'error' : ''}>
                                                    {file.name} ({getFileSizeText(file.size)})
                                                </h6>
                                                {progress[name].msg && (
                                                    <span className='msg'>{progress[name].msg}</span>
                                                )}
                                            </td>
                                            <td>

                                                {canEdit ? (
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        pattern="\d{4}-\d{2}-\d{2}"
                                                        onChange={event => updateFileDefinition(name, {effectiveDate: event.target.value})}
                                                        value={fileDef.effectiveDate}
                                                        disabled={!canEdit}
                                                    />
                                                ) : (
                                                    <div>{fileDef.effectiveDate || '-'}</div>
                                                )}
                                            </td>
                                            <td>
                                                {canEdit ? (
                                                    <CustomSelect
                                                        value={folders.find(f => f.id === fileDef.folderId).folderName}
                                                        options={folders && folders.map(f => ({value: f.folderName, label: f.folderName}))}
                                                        onChange={folderName => updateFileDefinition(name, {folderId: folders.find(f => f.folderName === folderName).id})}
                                                        disabled={!canEdit}
                                                    />
                                                ) : (
                                                    <div>{_.get(folders.find(f => f.id === fileDef.folderId), 'folderName', '-')}</div>
                                                )}
                                            </td>
                                            <td>
                                                {uploading && progress[name].percentage === 1
                                                    ? <progress max="100"/>
                                                    : <progress max="100" value={progress[name].percentage}/>
                                                }
                                            </td>
                                            <td>
                                                <button
                                                    className={"btn btn-link" + (progress[name].percentage === 100 ? " btn-success" : "")}
                                                    onClick={() => removeFile(name)}
                                                    disabled={progress[name].percentage > 0 || progress[name].percentage === 100}
                                                >
                                                    {progress[name].percentage === 100
                                                        ? <span>&#10003;</span>
                                                        : <span>&times;</span>
                                                    }
                                                </button>
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </table>

                        </div>

                        <div
                            className={"uploader-box-submit" + ((uploading || uploaded) ? " uploader-box-submit-message" : "")}
                            onClick={(e) => {e.stopPropagation()}}
                        >
                            {uploading
                            ?
                                <>
                                    <div className="left">
                                        <div>
                                            <span className="badge badge-white">&#x021BB;</span>
                                        </div>
                                        <div>
                                            <h6>Uploading...</h6>
                                            <p>
                                                {Object.keys(files).length - uploading} out of {Object.keys(files).length} has been uploaded.
                                                <br/>
                                                About 1 minute remaining.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={cancelUpload}
                                        >
                                            Cancel Upload
                                        </button>
                                    </div>
                                </>
                            : uploaded
                            ?
                                <>
                                    <div className="left">
                                        <div>
                                            <span className="badge badge-success">&#10003;</span>
                                        </div>
                                        <div>
                                            <h6>Upload complete</h6>
                                            <p>Add files to upload more</p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <p>
                                            Thank you for uploading your data to N1X10.
                                            <br/>
                                            It will take 3 days to organize the data. Meanwhile the file is available in the app.
                                        </p>
                                    </div>
                                </>
                            :
                                <button
                                    className="btn btn-primary"
                                    onClick={startUpload}
                                    disabled={uploading}
                                >
                                    Upload
                                </button>
                            }
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default FileUpload;
