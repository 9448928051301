import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import {sendCode} from "../../../actions/auth.actions";

const SendCode = ({ setActiveStep, sendCode }) => {

    const [loading, setLoading] = useState(false);

    const handleOnSendCodeClick = useCallback(async () => {
        setLoading(true);

        try {
            const response = await sendCode();
            setLoading(false);

            if (response && response.success) {
                setActiveStep("enterCode");
            }
        } finally {
            setLoading(false);
        }
    }, [sendCode, setActiveStep]);

    return (
        <>
            <h6 className="login-subtitle">Login</h6>

            <p className="login-description">
                A 4-digit code will be sent to this number:
                <br/>
                (054)-***-*626
            </p>

            <button
                type="button"
                className="btn btn-primary"
                onClick={handleOnSendCodeClick}
                disabled={loading}
            >
                Send Code
            </button>
        </>
    );
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    sendCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(SendCode);
