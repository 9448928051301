import React, {Component} from 'react';
import Header from "./Header";

const FileUploadLayout = ({ children }) => {

    return (
        <>
            <Header />
            <main>
                <div className="container">
                    {children}
                </div>
            </main>
        </>
    );
}

export default FileUploadLayout;
