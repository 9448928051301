import React from 'react';

const NotFound = () => {
    return (
        <>

            Not found

        </>
    );
}

export default NotFound;
