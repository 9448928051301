import axios from 'axios';
import {API_ROOT} from "../utils/api-config";
import {actionCreator, createRequestTypes, generateLocalStorageItem, getErrorMessage, getToken} from '../utils/helpers';

export const GET_PATIENT_DETAILS = createRequestTypes('GET_PATIENT_DETAILS');

export const getPatientDetails = (token) => async (dispatch) => {
    dispatch(actionCreator.request(GET_PATIENT_DETAILS));
    try {
        const response = await axios({
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': token || getToken()
            },
            method: 'GET',
            url: `${API_ROOT}api/patient/details`,
            params: {app: 'PATIENT_APP'},
        });
        const userId = (
            (response.data.details.firstName ? response.data.details.firstName.charAt(0) : '')
            + (response.data.details.lastName ? response.data.details.lastName.charAt(0) : '')
        ).toUpperCase();

        generateLocalStorageItem(token || getToken(), userId);
        dispatch(actionCreator.success(GET_PATIENT_DETAILS, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(GET_PATIENT_DETAILS, mockError));
        return err.response.data;
    }
};