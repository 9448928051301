import React, {Component} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {connect} from 'react-redux'
import Login from "./containers/Login/Login";
import {routes} from "./containers/Layout/routeConfig";
import NotFound from "./containers/NotFound/NotFound";
import ManagedRoute from "./utils/ManagedRoute";
import {ToastContainer} from "react-toastify";

class App extends Component {

    render() {

        return (
            <>
                <Routes>
                    <Route exact path={`/`} element={<Navigate to="/file-upload"/>}/>
                    <Route path="/login" element={<Login />}/>
                    {routes.map((route) => (
                        <Route path={route.url} element={<ManagedRoute route={route} />} />
                    ))}
                    <Route path="*" element={NotFound} />
                </Routes>
                <ToastContainer
                    position={"top-center"}
                    autoClose={5000}
                    hideProgressBar={true}
                    closeOnClick={true}
                    pauseOnHover={true}
                    draggable={true}
                    progress={undefined}
                />
            </>
        )
    }
}

const mapDispatchToProps = (state) => {
    return {
    }
}

export default connect(null, mapDispatchToProps)(App)
