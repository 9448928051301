import React, {Component, useCallback} from 'react';
import {connect} from 'react-redux';
import {logout} from '../../actions/auth.actions';
import {useNavigate} from "react-router-dom";

const Logout = ({ logout, pendingFiles, showLogoutModal }) => {

    const navigate = useNavigate();

    const handleOnLogoutClick = useCallback(() => {
        logout(navigate);
    }, [logout, navigate]);

    return (
        <div className="logout">
            <div className="logout-box">

                <h5 className="logout-title">Are you sure you want to leave?</h5>

                {pendingFiles > 0 &&
                <p>{pendingFiles} {pendingFiles > 1 ? "files haven't " : "file hasn't "}been uploaded yet</p>
                }

                <div className="logout-buttons">
                    <button
                        className="btn btn-outline-primary"
                        onClick={handleOnLogoutClick}
                    >
                        Leave anyway
                    </button>
                    <button
                        className="btn btn-primary"
                        onClick={showLogoutModal}
                    >
                        Cancel
                    </button>
                </div>

            </div>

            <div className="logout-overlay" />

        </div>
    );
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
