import {combineReducers} from 'redux';
import {authReducer} from "./auth.reducer";
import {patientReducer} from "./patient.reducer";
import {errorReducer} from "./error.reducer";
import {loadingReducer} from "./loading.reducer";
import {LOGOUT} from "../actions/auth.actions";

const appReducer = combineReducers({
    auth: authReducer,
    patient: patientReducer,
    errors: errorReducer,
    loading: loadingReducer,
});

export const rootReducer = (state, action) => {
    if (action.type === LOGOUT.SUCCESS) {
        state = undefined;
    }

    return appReducer(state, action);
};

