import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getAuthData} from "../../utils/selectors";
import {useLocation, useNavigate} from "react-router-dom";
import MessageStep from "./Common/MessageStep";
import CodeStep from "./ResetPasswordSteps/CodeStep";
import useSteps from "../../hooks/useSteps";
import NewPasswordStep from "./ResetPasswordSteps/NewPasswordStep";
import {toast} from "react-toastify";
import _ from "lodash";

const STEP = {
    CODE: "CODE",
    NEW_PASSWORD: "NEW_PASSWORD",
    CONFIRMATION: "CONFIRMATION",
    ERROR: "ERROR",
}

const ResetPassword = ({isLoggedIn}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');

    const [step, stepState, prevStep, setStep] = useSteps(token ? STEP.CODE : STEP.ERROR, {
        [STEP.CODE]: {
            codeToken: token,
        }
    });

    const handleOnAuthCodeSuccess = useCallback(async (passwordToken, code) => {
       setStep(STEP.NEW_PASSWORD, {
           passwordToken: passwordToken,
           code: code,
       })
    }, []);

    const handleOnAuthPasswordSuccess = useCallback(async (authToken) => {
        setStep(STEP.CONFIRMATION, {
            authToken: authToken
        })
    }, []);

    const handleOnCodeStepError = useCallback((error) => {
        toast.error(error.message);
        setStep(STEP.ERROR, {
            errorMessage: _.get(error, 'response.data.errorMessage', 'Invalid token'),
        });
    }, []);

    useEffect(() => {
        if (isLoggedIn === true) {
            navigate('/');
        }
    }, [])

    if (!token) {
        return <MessageStep
            title="Reset Password"
            description={
                <p className="text-center">
                    <span className="text-danger">Invalid token</span>
                </p>
            } />
    }

    if (step === STEP.CODE) {
        return <CodeStep
            codeToken={stepState.codeToken}
            onError={handleOnCodeStepError}
            onAuthTokenReceived={handleOnAuthCodeSuccess}/>
    } else if (step === STEP.NEW_PASSWORD) {
        return <NewPasswordStep
            passwordToken={stepState.passwordToken}
            code={stepState.code}
            onAuthTokenReceived={handleOnAuthPasswordSuccess}/>
    } else if (step === STEP.CONFIRMATION) {
        return (
            <MessageStep
                title="Reset Password"
                description={
                    <div>
                        <div className="auth-new-password__result">
                            Success
                        </div>
                        <div className="auth-new-password__result-info">
                            You can now login to the app with your new password.
                        </div>
                    </div>
                }
            />
        )
    } else if (step === STEP.ERROR) {
        return (
            <MessageStep
            title="Reset Password"
            description={
                <p className="text-center">
                    <span className="text-danger">{stepState.errorMessage || 'Unexpected error'}</span><br/>
                    Please contact info@n1x10.com for assistance in login.
                </p>
            }
        />
        )
    } {
        return null;
    }
}

const mapStateToProps = (state) => {
    const isLoggedIn = getAuthData(state).isLoggedIn;

    return {
        isLoggedIn,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);