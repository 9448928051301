import React from 'react';

const InvalidToken = () => {
    return (
        <>
            <h6 className="login-subtitle">Invalid Link</h6>
            <p className="login-description">
                The link is expired or invalid.
            </p>
        </>
    );
}

export default (InvalidToken);
