import React, {useCallback, useState} from 'react';
import {connect} from 'react-redux';
import AuthCode from "react-auth-code-input";
import {useNavigate} from "react-router-dom";
new RegExp('^[0-9]$');

const EnterCode = ({ verifyCode }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [isIncorrect, setIsIncorrect] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");

    const navigate = useNavigate();

    const handleOnVerifyCode = useCallback(async (event) => {
        event.preventDefault();
        setIsLoading(true);

        try {
            const response = await verifyCode(verificationCode);

            if (response.success) {
                navigate('/file-upload');
            } else {
                setIsIncorrect(true);
            }
        } finally {
            setIsLoading(false);
        }
    }, [verificationCode]);

    return (
        <>
            <h6 className="login-subtitle">Enter verification code</h6>

            <p className="login-description">
                A 4-digit code was sent to your
                <br/>
                mobile phone (054)-***-*626
            </p>

            <form
                className="login-form"
                autoComplete="off"
                onSubmit={(e) => this.verifyCode(e)}
            >

                <div className="login-auth-code mb-2">
                    <AuthCode
                        inputClassName="auth-code-input"
                        allowedCharacters='numeric'
                        length={4} onChange={setVerificationCode}
                    />
                </div>

                {(!isExpired && !isIncorrect) ? (
                    <>
                        <p>The code will expire within 6 minutes.</p>
                        <p>
                            Didn't receive a code? <button className="btn btn-link">Resend code</button>
                            <br/>
                            <button className="btn btn-link">Get code via voice call</button>
                        </p>
                    </>
                ) : null}

                {isExpired ? (
                    <>
                        <p>
                            <span className="text-danger">The code is no longer valid,</span> please request a new code.
                        </p>
                        <p>
                            <button className="btn btn-link">Resend code</button>
                            <br/>
                            <button className="btn btn-link">Get code via voice call</button>
                        </p>
                    </>
                ) : null}

                {isIncorrect ? (
                    <>
                        <p>
                            <span className="text-danger">Incorrect code</span>, please enter code again.
                        </p>
                        <p>
                            Didn't receive a code? <button className="btn btn-link">Resend code</button>
                            <br/>
                            <button className="btn btn-link">Get code via voice call</button>
                        </p>
                    </>
                ) : null}

                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={handleOnVerifyCode}
                    disabled={isLoading || verificationCode.length < 4}
                >
                    Verify
                </button>

            </form>
        </>
    );
}

const mapStateToProps = (state) => {

    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterCode);
