import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {isEmpty, setFileToken} from "../../utils/helpers";
import axiosLinkInstance from "../../utils/axios-link-instance";
import axios from "axios";
import _ from "lodash";
import moment from "moment-timezone";
import nounDocumentSvg from "../../images/noun-document.svg";
import cyrcleExclamationSolidSvg from "../../images/circle-exclamation-solid.svg";
import checkCyrcleSolidSvg from "../../images/check-circle-solid.svg";
import closeButtonSvg from "../../images/close-button.svg";
import hourglassClockLightSvg from "../../images/hourglass-clock-light.svg";
import {useLocation} from "react-router-dom";

const LinkUploadFiles = () => {

    const [files, setFiles] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [fileDefinitions, setFileDefinitions] = useState({});
    const [defaultFolder, setDefaultFolder] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [progress, setProgress] = useState({});
    const [uploadedProgress, setUploadedProgress] = useState({});
    const [stoppers, setStoppers] = useState({});
    const [goodLink, setGoodLink] = useState(false);
    const [captchaValue, setCaptchaValue] = useState("");
    const [captchaText, setCaptchaText] = useState("");
    const [captchaError, setCaptchaError] = useState(false);
    const stoppersRef = useRef(stoppers);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setFileToken(token);

    useEffect(() => {
        axiosLinkInstance.get(`validate-token`).then((resp) => {
            setGoodLink(true);
        }).catch((error) => {
        });

        moment.tz.setDefault("UTC");
    }, [token]);

    const loadCaptcha = () => {
        axiosLinkInstance.get(`/captcha/new`).then((resp) => {
            setCaptchaText(resp.data.data);
        }).catch((error) => {
        });
    }

    useEffect(() => {
        loadCaptcha();
    }, []);

    const onDrop = useCallback(acceptedFiles => {
    }, []);

    const {
        rootRef, inputRef,
        getRootProps, getInputProps,
        isDragAccept, isDragActive, isDragReject, isFileDialogActive, isFocused,
        acceptedFiles, draggedFiles, fileRejections,
        open,
    } = useDropzone({
        onDrop,
        accept: {
            'text/pdf': ['.pdf'],
        }
    });

    const removeFile = (name) => {
        const updatedFiles = {...files};
        delete updatedFiles[name];
        setFiles(updatedFiles);

        const updatedFileDefinitions = {...fileDefinitions};
        delete updatedFileDefinitions[name];
        setFileDefinitions(updatedFileDefinitions);

        const updatedProgress = {...progress};
        delete updatedProgress[name];
        setProgress(updatedProgress);

        const updatedStoppers = {...stoppers};
        delete updatedStoppers[name];
        setStoppers(updatedStoppers);
    }

    const removeAll = () => {
        setFiles({});
        setFileDefinitions({});
        setProgress({});
        setStoppers({});
    }

    const validateCaptchaAndStartUpload = (shouldStartUpload = true) => {
        setCaptchaError(false);
        if (isEmpty(captchaValue)) {
            return;
        }
        axiosLinkInstance.get(`captcha/validate?captcha=${captchaValue}`)
            .then((resp) => {
                if (shouldStartUpload !== false) {
                    startUpload();
                }
            })
            .catch((err) => {
                setCaptchaError(true);
                setCaptchaValue("");
                loadCaptcha();
            });
    }

    useEffect(() => {
        if (!isEmpty(captchaValue)) {
            validateCaptchaAndStartUpload(false);
        }
    }, [captchaValue]);

    const startUpload = () => {

        // Generate progress starting points
        let updatedProgress = {...progress};
        Object.keys(updatedProgress).map((name) => {
            if (updatedProgress[name].percentage === 0) updatedProgress[name].percentage = 1;
        });
        setProgress(updatedProgress);

        // Generate stop tokens
        let updatedStoppers = {};
        Object.keys(files).forEach((name, index) => {

            // Only for still not uploaded files
            if (progress[name].percentage !== 100) {
                updatedStoppers[name] = axios.CancelToken.source();
            }
        });
        setStoppers(updatedStoppers);

        Object.keys(files).forEach((name, index) => {

            // Don't upload files a second time
            if (progress[name].percentage === 100) {
                return;
            }

            if (files[name].size === 0) {
                setProgress((prevProgress) => {
                    const newProgress = {...prevProgress};
                    const fileProgress = {...newProgress[name]};

                    fileProgress.percentage = 100;
                    fileProgress.success = false;

                    fileProgress.msg = 'The file is empty, cannot be uploaded';

                    newProgress[name] = fileProgress;
                    return newProgress;
                });
                return;
            } else if (files[name].size > 20000000) {
                setProgress((prevProgress) => {
                    const newProgress = {...prevProgress};
                    const fileProgress = {...newProgress[name]};

                    fileProgress.percentage = 100;
                    fileProgress.success = false;

                    fileProgress.msg = 'The file is bigger than 20MB, cannot be uploaded';

                    newProgress[name] = fileProgress;
                    return newProgress;
                });
                return;
            }
            const fileDefinition = fileDefinitions[name];

            // Get the file to upload
            let formData = new FormData();
            formData.append("file", files[name]);
            formData.append("captcha", captchaValue);
            for (const prop in fileDefinition) {
                const propVal = fileDefinition[prop];
                if (propVal !== undefined) {
                    formData.append(prop, fileDefinition[prop]);
                }
            }

            // Fire the API call
            axiosLinkInstance.post(`upload-file`, formData, {
                headers: {'Content-Type': 'multipart/form-data'},
                cancelToken: updatedStoppers[name].token,
                onUploadProgress: (progressEvent) => {

                    // Calculate progress percentage
                    let progressPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);

                    // Work around very small files going from 0 straight to 100
                    if (progressPercentage === 0) {
                        progressPercentage = 1;
                    }
                    if (progressPercentage === 100) {
                        progressPercentage = 99;
                    }

                    // Store progress
                    setProgress((prevProgress) => {
                        prevProgress[name] = {percentage: progressPercentage};
                        return prevProgress;
                    });
                }
            })
                .then((response) => {
                    setProgress((prevProgress) =>  {
                        const newProgress = {...prevProgress};
                        const fileProgress = {...newProgress[name]};

                        fileProgress.percentage = 100;
                        if (response && response.data && response.data.success) {
                            fileProgress.success = true;

                            // if (response.data.message) {
                            //     fileProgress.msg = response.data.message;
                            // }
                            //
                            const fileId = _.get(response.data, 'actionResults[0].file.id');
                            if (fileId) {
                                const updatedFileDefinition = {...fileDefinitions[name], id: fileId};
                                setFileDefinitions({...fileDefinitions, [name]: updatedFileDefinition});
                            }
                        } else {
                            fileProgress.success = false;

                            // Log error
                            console.log(response && response.data && response.data.message)
                        }
                        newProgress[name] = fileProgress;
                        return newProgress;
                    });
                })
                .catch((error) => {
                    setProgress((prevProgress) => {
                        const newProgress = {...prevProgress};
                        const fileProgress = {...newProgress[name]};
                        const responseData = (error && error.response && error.response.data) || null;

                        fileProgress.percentage = 100;
                        fileProgress.success = false;

                        if (responseData && responseData.success === false) {
                            if (responseData.errorMessage) {
                                if (responseData.errorMessage.startsWith('Invalid captcha')){
                                    loadCaptcha();
                                }
                                fileProgress.msg = responseData.errorMessage;
                            }
                        }

                        newProgress[name] = fileProgress;
                        return newProgress;
                    });


                    // Log error
                    console.log(error)
                })
                .finally(() => {

                    let updatedStoppers = {...stoppersRef.current};
                    delete updatedStoppers[name];
                    setStoppers(updatedStoppers);
                });
        });
    }

    const cancelUpload = () => {

        // Get current progress
        let updatedProgress = {...progress};

        Object.keys(files).map(name => {

            // For files that are still uploading
            if (progress[name].percentage < 100) {

                // Stop API Call
                stoppers[name].cancel();

                // Reset Progress
                updatedProgress[name].percentage = 0;
            }
        });

        // Update progress
        setProgress(updatedProgress);
    }

    const getFileSizeText = (bytes) => {
        if (bytes == null) {
            bytes = 0;
        }

        if (bytes < 1_000) {
            return bytes + " bytes"
        } else if (bytes < 1_000_000) {
            return (bytes / 1_000) + " KB";
        } else if (1_000_000_000) {
            return (bytes / 1_000_000) + " MB";
        } else {
            return (bytes / 1_000_000_000) + " GB";
        }
    }

    const updateFileDefinition = useCallback((fileName, fileDefinition) => {
        const updatedDefinitions = {...fileDefinitions};

        updatedDefinitions[fileName] = {...updatedDefinitions[fileName], ...fileDefinition};

        setFileDefinitions(prevState => {
            const updatedDefinitions = {...prevState};

            updatedDefinitions[fileName] = {...updatedDefinitions[fileName], ...fileDefinition};

            return updatedDefinitions;
        });
    }, []);

    const onRecaptcha = useCallback(() => {

    });

    // On files added
    useEffect((prevProps) => {
        let localFiles = {...files};
        let updatedFileDefinitions = {...fileDefinitions};

        if (uploaded === true) {
            setUploadedFiles({...files, ...uploadedFiles});
            setUploadedProgress({...progress, ...uploadedProgress});
            localFiles = {};
            updatedFileDefinitions = {};
        }


        // Update accepted files
        acceptedFiles.forEach(file => {
            // If file is already selected - alert user
            if (localFiles && localFiles.hasOwnProperty(file.name) && localFiles[file.name].size === file.size) {
                // alert(file.name + ' already selected')
            }
            // If file is new - add
            else {
                let effectiveDate = null;
                const dateMatch = file.name && file.name.match(/^(\d{2}-\d{2}-\d{4})#.*#/i);
                if (dateMatch && dateMatch.length > 1) {
                    effectiveDate = moment(dateMatch[1], 'MM-DD-YYYY').format('yyyy-MM-DD');
                } else {
                    effectiveDate = moment().format('yyyy-MM-DD');
                }

                localFiles[file.name] = file;
                updatedFileDefinitions[file.name] = {
                    fileName: file.name,
                    contentType: file.type,
                };
            }
        });
        setFiles(localFiles);
        setFileDefinitions(updatedFileDefinitions);

        // Generate upload progress entry
        let updatedProgress = {...progress};
        Object.keys(localFiles).forEach((name) => {
            if (!progress.hasOwnProperty(name)) updatedProgress[name] = {percentage: 0};
        });
        setProgress(updatedProgress);

    }, [acceptedFiles]);

    // On progress update
    useEffect(() => {

        // Update uploading status
        setUploading(Object.keys(progress).filter(file => progress[file].percentage > 0 && progress[file].percentage < 100).length);

        // Update uploaded status
        setUploaded(Object.keys(progress).length > 0 && Object.keys(progress).length === Object.keys(progress).filter(name => progress[name].percentage === 100).length);

    }, [progress]);

    // On stoppers update
    useEffect(() => {

        // Update stoppers ref for use in async calls
        stoppersRef.current = stoppers;

    }, [stoppers]);

    const fileButton = (fileProgress) => {
        if (fileProgress.success === false) {
            return (<img src={cyrcleExclamationSolidSvg}/>);
        }
        if (fileProgress.success === true) {
            return (<img src={checkCyrcleSolidSvg}/>);
        }
        return (<img src={closeButtonSvg}/>);
    }

    return (
        <div className="link-uploader">
            {!goodLink && (
                <div className="row">
                    <img width={154} height={137} src={hourglassClockLightSvg}/>
                    <span className="text-center" style={{"margin-top": "46px", "font-size": "36px", "font-weight": "500"}}>This upload link has expired.</span>
                    <span className="text-center" style={{"margin-top": "35px", "font-size": "20px"}}>
                        This link cannot be used anymore.<br/>
For assistance send a message to <a href="mailto: info@n1x10.com">info@n1x10.com</a>.</span>
                </div>
            )}
            {goodLink && (<div className="row">
                <div className="col col-6">

                    <h2>N1X10 Multiple file upload</h2>

                    <p className="link-uploader-input">
                        <button
                            type="button"
                            className="btn btn-purple"
                            onClick={open}
                            disabled={uploading}
                        >
                            <span>+</span>
                        </button>
                        Drag and drop files onto this window to upload or browse your computer to select a file
                    </p>
                    <span className="info-line">
                        * Please note that only PDF files up to 20MB can be uploaded.
                    </span>

                </div>
                <div className="col col-6">
                    <div
                        className={"link-uploader-box" + (isDragActive ? " uploader-box-dragging" : "")}
                        {...getRootProps()}
                    >

                        <div
                            className="link-uploader-box-header"
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            <h4 className="link-uploader-box-title">Files to upload</h4>
                            {!isEmpty(files) &&
                                <button
                                    className="btn btn-link"
                                    onClick={removeAll}
                                    disabled={uploading}
                                >
                                    Remove all
                                </button>
                            }
                        </div>

                        <div
                            className="link-uploader-box-files"
                            onClick={(e) => {
                                !isEmpty(files) && e.stopPropagation()
                            }}
                        >

                            <input {...getInputProps()} />

                            <table>
                                {files && Object.keys(files).map((name, index) => {
                                        const file = files[name];
                                        const canEdit = !progress[name].percentage;

                                        return (
                                            <tr key={file.name}>
                                                <td width="5%">{index + 1}</td>
                                                <td width="5%"><img src={nounDocumentSvg}/></td>
                                                <td width="65%">
                                                    <h6 className={progress[name].success === false ? 'error' : ''}>
                                                        {file.name} ({getFileSizeText(file.size)})
                                                    </h6>
                                                    {progress[name].msg && (
                                                        <span className='msg'>{progress[name].msg}</span>
                                                    )}
                                                </td>
                                                <td width="20%">
                                                    {uploading !== 0 && progress[name].percentage !== 100  && (progress[name].percentage === 1
                                                            ? <progress max="100"/>
                                                            : <progress max="100" value={progress[name].percentage}/>
                                                    )}
                                                </td>
                                                <td width="5%">
                                                    <button
                                                        className={"btn" + (progress[name].percentage > 0? " inactive": "")}
                                                        onClick={() => removeFile(name)}
                                                        disabled={progress[name].percentage > 0}
                                                    >
                                                        {fileButton(progress[name])}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                )}
                            </table>
                            {!isEmpty(uploadedFiles) && (<>
                                <div className="completed-files-title">
                                  Uploaded Files
                                </div>
                                <table>
                                    {Object.keys(uploadedFiles).map((name, index) => {
                                            const file = uploadedFiles[name];

                                            return (
                                                <tr key={file.name}>
                                                    <td width="5%">{index + 1}</td>
                                                    <td width="5%"><img src={nounDocumentSvg}/></td>
                                                    <td width="65%">
                                                        <h6 className={uploadedProgress[name].success === false ? 'error' : ''}>
                                                            {file.name} ({getFileSizeText(file.size)})
                                                        </h6>
                                                        {uploadedProgress[name].msg && (
                                                            <span className='msg'>{uploadedProgress[name].msg}</span>
                                                        )}
                                                    </td>
                                                    <td width="20%">
                                                    </td>
                                                    <td width="5%">
                                                        <button
                                                            className={"btn" + (uploadedProgress[name].percentage > 0? " inactive": "")}
                                                            disabled={true}
                                                        >
                                                            {fileButton(uploadedProgress[name])}
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )}
                                </table>
                            </>)}

                        </div>

                        <div
                            className={"link-uploader-box-submit uploader-box-submit-message"}
                            onClick={(e) => {
                                e.stopPropagation()
                            }}
                        >
                            {uploading
                                ?
                                <>
                                    <div className="left">
                                        <div>
                                            <span className="badge badge-white">&#x021BB;</span>
                                        </div>
                                        <div>
                                            <h6>Uploading...</h6>
                                            <p>
                                                {Object.keys(files).length - uploading} out
                                                of {Object.keys(files).length} has been uploaded.
                                                {/*<br/>*/}
                                                {/*About 1 minute remaining.*/}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <button
                                            className="btn btn-outline-primary"
                                            onClick={cancelUpload}
                                        >
                                            Cancel Upload
                                        </button>
                                    </div>
                                </>
                                : uploaded
                                    ?
                                    <>
                                        <div className="left">
                                            <div>
                                                <span className="badge badge-success"><img width="40px" height="40px" src={checkCyrcleSolidSvg}/></span>
                                            </div>
                                            <div>
                                                <h6>Upload complete</h6>
                                                <p>Add files to upload more</p>
                                            </div>
                                        </div>
                                    </>
                                    : <>
                                        <div className="right">
                                            {captchaText} = <input style={{width: "50px", textAlign: "center"}} value={captchaValue} onChange={(e) => setCaptchaValue(e.target.value)}/>
                                            {captchaError && (
                                                <div style={{display: "inline-block"}} className="invalid-feedback">Invalid value</div>
                                            )}
                                        </div>
                                        <div className="left">
                                            <button
                                                className="btn btn-purple radius-6 right"
                                                onClick={validateCaptchaAndStartUpload}
                                                disabled={uploading || isEmpty(captchaValue) || captchaError || isEmpty(files)}
                                            >
                                                Upload
                                            </button>
                                        </div>
                                    </>
                            }
                        </div>

                    </div>

                </div>
            </div>)}
        </div>
    )
}

export default LinkUploadFiles;
