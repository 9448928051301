import React, {useCallback, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {logout} from "../../actions/auth.actions";
import {getAuthData} from "../../utils/selectors";
import Logout from "../Logout/Logout";
import {getUserId} from "../../utils/helpers";

const Header = ({ isLoggedIn, patientInitials, pendingFiles }) => {

    const [isLoggingOut, setIsLoggingOut] = useState(false);

    const showLogoutModal = useCallback(() => {
        setIsLoggingOut(prevState => !prevState);
    }, []);

    return (
        <>
            <header className={"header" + (isLoggedIn ? " header-user" : " header-guest")}>

                <div className="header-brand">
                    <Link to="/search-patients" className="header-logo">
                        <img src={isLoggedIn ? "/logo.jpg" : "/logo-white.png"} alt="Nixio" />
                    </Link>
                    Transforming Your Cancer Journey
                </div>

                {isLoggedIn ? (
                    <div className="header-menu">

                        <button
                            className="btn btn-link"
                            onClick={showLogoutModal}
                        >
                            Logout
                        </button>

                        <button className="btn btn-primary" disabled>
                            {patientInitials}
                        </button>

                    </div>
                ): null}
            </header>

            {isLoggedIn && isLoggingOut &&
            <Logout
                showLogoutModal={showLogoutModal}
                pendingFiles={pendingFiles}
            />
            }
        </>
    );
}

const mapStateToProps = (state) => {
    const isLoggedIn = getAuthData(state).isLoggedIn;
    const patientInitials = getUserId();

    return {
        isLoggedIn,
        patientInitials,
    }
};

const mapDispatchToProps = {
    logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
