import {API_ROOT} from "../utils/api-config";
import axios from 'axios';
import axiosInstance from "../utils/axios-instance";

import {
    actionCreator,
    createRequestTypes,
    generateLocalStorageItem, cleanLocalStorage,
    getToken, getUserId, getCode, getErrorMessage,
    isEmpty,
} from '../utils/helpers';

export const LOGOUT = createRequestTypes('LOGOUT');
export const SEND_CODE = createRequestTypes('SEND_CODE');
export const VERIFY_CODE = createRequestTypes('VERIFY_CODE');

export const logout = (navigate) => {
    return (dispatch) => {
        axios({
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': getToken()
            },
            method: 'GET',
            url: `${API_ROOT}api/logout`,
        }).catch(() => {
            // ignoring
        }).finally(() => {
            cleanLocalStorage();
            dispatch(actionCreator.success(LOGOUT));
            if (navigate) {
                navigate('/login')
            }
        })
    };
};

export const sendCode = () => async (dispatch) => {
    dispatch(actionCreator.request(SEND_CODE));
    try {
        const response = await axiosInstance.get(`/api/patient/send-verification`);
        // const response = {data: {success: true}}
        dispatch(actionCreator.success(SEND_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = getErrorMessage(err.response);
        dispatch(actionCreator.failure(SEND_CODE, mockError));
        return err;
    }
};

export const verifyCode = (verificationCode) => async (dispatch) => {
    dispatch(actionCreator.request(VERIFY_CODE));
    try {
        const response = await axios({
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': getToken()
            },
            method: 'GET',
            url: `${API_ROOT}api/patient/verify-code`,
            params: {
                code: verificationCode
            },
        });
        if (response.data.success) {
            generateLocalStorageItem(getToken(), getUserId(), verificationCode);
        }
        dispatch(actionCreator.success(VERIFY_CODE, response.data));
        return response.data;
    } catch (err) {
        let mockError = {message: 'Invalid username or password'};
        dispatch(actionCreator.failure(VERIFY_CODE, mockError));
        return err;
    }
};

export const checkAuthStatus = (history) => {
    return (dispatch) => {
        if (isEmpty(getCode()) || isEmpty(getToken())) {
            logout(history);
        }
    };
};