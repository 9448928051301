import React from 'react';
import {connect} from 'react-redux';

const AuthBaseLayout = (props) => {

    return (
        <div className="auth-base-layout">
            <div className="auth-base-layout__header">
                <div className="auth-base-layout__header-logo"/>
                <div className="auth-base-layout__header-text">
                    Transforming Your Cancer Journey
                </div>
            </div>
            <div className="auth-base-layout__container">
                {props.children}
            </div>
            <div className="auth-base-layout__footer">
                <div>Trouble logging in?</div>
                <div>Please contact info@n1x10.com</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthBaseLayout);