import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';

import {rootReducer} from './reducers';
import {checkAuthStatus} from "./actions/auth.actions";

const initialState = {};

const enhancers = [applyMiddleware(thunk)];

const store = createStore(rootReducer, initialState, compose(...enhancers));

store.dispatch(checkAuthStatus());

export default store;
