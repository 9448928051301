import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";
import Loader from "react-spinners/BeatLoader";
import logo from "../../images/nixio-logo.svg";
import userAvatar from "../../images/user-avatar.svg";
import moment from "moment";
import axiosLinkInstance from "../../utils/axios-link-instance";
import {setFileToken} from "../../utils/helpers";

const LinkUploadFilesLayout = ({children}) => {
    const [loadingPatientInfo, setLoadingPatientInfo] = useState(true);
    const [patientInfo, setPatientInfo] = useState();
    const [loadingPatientPhoto, setLoadingPatientPhoto] = useState(true);
    const [patientPhoto, setPatientPhoto] = useState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    setFileToken(token);

    useEffect(() => {
        axiosLinkInstance.get('patient/info').then((resp) => {
            setPatientInfo(resp.data);
            setLoadingPatientInfo(false);
        });
        axiosLinkInstance.get(`patient/image`, {responseType: 'blob'}).then((resp) => {
            setPatientPhoto(resp.data);
            setLoadingPatientPhoto(false);
        }).catch((error) => {
            setLoadingPatientPhoto(false);
        });
    }, [token]);

    return (
        <>
            <div className="upload-files-header">
                <div className="header-logo">
                    <img src={logo} alt="logo"/>
                </div>

                <div className="header-info">
                    <div className="document-info-toolbar flex-grow-1">
                        <div className="info-left">
                            <div className="patient-menu-info">
                                {loadingPatientInfo ? (
                                    <div className="patient-menu-loader">
                                        <Loader size={5} color={"#000"}/>
                                    </div>
                                ) : !!patientInfo ? (
                                    <>
                                        {loadingPatientPhoto ? (
                                            <div className="patient-menu-loader p-1">
                                                <Loader size={5} color={"#000"}/>
                                            </div>
                                        ) : (
                                            <div className="patient-menu-image">
                                                {patientPhoto ? (
                                                    <img src={URL.createObjectURL(new Blob([patientPhoto]))} alt="Patient Photo"/>
                                                ) : (
                                                    <img src={userAvatar} alt="Patient Photo"/>
                                                )}
                                            </div>)}
                                        <div className="patient-menu-details">
                                            <div className="row">
                                                <div className="col">
                                                <span
                                                    className="patient-menu-detail patient-menu-detail-full">{patientInfo.firstName + " " + patientInfo.lastName}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                <span
                                                    className="patient-menu-detail">{patientInfo.birthdate && moment(patientInfo.birthdate).format("MM/DD/YYYY")}</span>
                                                    <span
                                                        className="patient-menu-detail">{patientInfo.gender && patientInfo.gender}</span>
                                                    <span
                                                        className="patient-menu-detail">{patientInfo.birthdate ? moment().diff(patientInfo.birthdate, "years") : '-'} YO</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>) : (
                                    <></>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main>
                <div className="link-container">
                    {children}
                </div>
            </main>
        </>
    );
}

export default LinkUploadFilesLayout;
